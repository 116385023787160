<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="5">
        <v-card class="elevation-12">
          <v-toolbar dark flat>
            <v-toolbar-title>{{
              $t("forms.passwordReset.title")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-title v-if="success">
            {{ $t(success) }}
          </v-card-title>

          <v-card-text v-else>
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('forms.general.password.label')"
                    type="password"
                    v-model="form.password"
                    :error-messages="passwordErrors"
                    :success="shouldAppendValidClass($v.form.password)"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :label="$t('forms.general.passwordConfirmation.label')"
                    type="password"
                    v-model="form.passwordConfirmation"
                    :error-messages="passwordConfirmationErrors"
                    :success="
                      shouldAppendValidClass($v.form.passwordConfirmation)
                    "
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-alert
                    v-if="error"
                    class="mt-6"
                    dense
                    outlined
                    type="error"
                  >
                    {{ $t(error) }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn v-if="success" color="primary" :to="{ name: 'Login' }">
              {{ $t("forms.passwordReset.toLogin") }}
            </v-btn>

            <v-btn
              v-else
              @click="submitForm"
              color="success"
              :loading="loading"
            >
              {{ $t("forms.passwordReset.submit") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import Repository from "../store/api/repository";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "PasswordReset",
  data() {
    return {
      form: {
        email: null,
        password: null
      },
      loading: false,
      success: null,
      error: null
    };
  },
  head() {
    return {
      link: [
        {
          rel: "canonical",
          href: `https://alge-results.com/password-reset`
        }
      ]
    };
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(6)
      },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required &&
        errors.push(this.$i18n.t("forms.general.password.errors.required"));
      !this.$v.form.password.minLength &&
        errors.push(
          this.$i18n.t("forms.general.password.errors.minLength", { length: 6 })
        );
      return errors;
    },
    passwordConfirmationErrors() {
      const errors = [];
      if (!this.$v.form.passwordConfirmation.$dirty) return errors;
      !this.$v.form.passwordConfirmation.required &&
        errors.push(
          this.$i18n.t("forms.general.passwordConfirmation.errors.required")
        );
      !this.$v.form.passwordConfirmation.sameAsPassword &&
        errors.push(
          this.$i18n.t("forms.general.passwordConfirmation.errors.match")
        );
      return errors;
    }
  },
  methods: {
    shouldAppendValidClass(field) {
      return !field.$invalid && field.$model && field.$dirty;
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    submitForm() {
      this.success = null;
      this.error = null;

      this.$v.form.$touch();

      if (!this.$v.form.$invalid && !this.loading) {
        this.loading = true;

        if (this.$route.query.token) {
          const token = this.$route.query.token;
          const parsedToken = this.parseJwt(token);

          const payload = {
            email: parsedToken.sub,
            password: this.form.password,
            token
          };

          Repository.post("password/reset/submit", payload)
            .then(response => {
              this.loading = false;

              if (response.data.status !== 0) {
                return Promise.reject(response);
              }

              this.success = "forms.passwordReset.success";
            })
            .catch(response => {
              this.loading = false;

              switch (response.data.status) {
                case -1000:
                  this.error = "forms.passwordReset.errors.invalidCredentials";
                  break;
                default:
                  this.error = "errors.unexpectedError";
              }
            });
        } else {
          this.loading = false;
          this.error = "forms.passwordReset.errors.invalidCredentials";
        }
      }
    }
  }
};
</script>
